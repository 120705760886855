import React from 'react'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { FormRadioGroup } from '@saatva-bits/pattern-library.components.form-radio-group'
import { FormToggle } from '@saatva-bits/pattern-library.components.form-toggle'
import { sortBy } from 'lodash'
import { PRODUCT_CODES } from '@/constants'
import styles from './CrossSellLinkToggle.module.scss'

const {
    AMALFI,
    AMALFI_WITH_STORAGE,
    MINORI,
    MINORI_WITH_STORAGE,
    HALLE,
    HALLE_WITH_STORAGE,
    LYON,
    LYON_WITH_STORAGE,
    CASSIS,
    CASSIS_WITH_STORAGE,
    DOWN_ALTERNATIVE_COMFORTER,
    LIGHTWEIGHT_DOWN_ALTERNATIVE_COMFORTER,
    HEAVYWEIGHT_COMFORTER
} = PRODUCT_CODES

const bedFrames = [
    AMALFI, AMALFI_WITH_STORAGE, MINORI, MINORI_WITH_STORAGE,
    HALLE, HALLE_WITH_STORAGE, LYON, LYON_WITH_STORAGE,
    CASSIS, CASSIS_WITH_STORAGE
]

const setOptionName = (productCode) => {
    const isStandard = bedFrames.includes(productCode)
    return productCode.toLowerCase().includes('storage') ? 'Storage' : isStandard ? 'Standard' : 'Slatted Platform'
}

const CrossSellLinkToggle = ({ productCode }) => {
    const { crossSellLinkProduct, category } = useProductData(productCode)
    const formattedCategory = category.toLowerCase()
    const isBedFrame = bedFrames.includes(productCode)
    const comfortersSorted = [
        {
            label: 'Lightweight',
            value: `/${formattedCategory}/${LIGHTWEIGHT_DOWN_ALTERNATIVE_COMFORTER}`,
        },
        {
            label: 'All-Year',
            value: `/${formattedCategory}/${DOWN_ALTERNATIVE_COMFORTER}`,
        },
        {
            label: 'Heavyweight',
            value: `/${formattedCategory}/${HEAVYWEIGHT_COMFORTER}`,
        }
    ]

    if (!crossSellLinkProduct || crossSellLinkProduct.length === 0) {
        return null
    }

    const dataSrcByType = 
        [
            {
                label: setOptionName(productCode),
                value: `/${formattedCategory}/${productCode}`,
            },
            {
                label: setOptionName(crossSellLinkProduct[0]),
                value: `/${formattedCategory}/${crossSellLinkProduct[0]}`,
            },
        ]

    const dataSrc = isBedFrame
        ? sortBy(dataSrcByType, 'label')
        : comfortersSorted

    const handleChange = (value) => {
        window.location.href = value
    }

    return (
        <div className='u-marginBottom--md'>
            <span className={styles.title}>{isBedFrame ? 'Select bed frame option' : 'Select comforter weight'}</span>
            <FormRadioGroup
                key="radio-group-cross-sell-link"
                className={styles.formRadio}
                name={`product-option-${productCode}`}
                dataSrc={dataSrc}
                initialSelection={`/${formattedCategory}/${productCode}`}
                onSelectionUpdate={handleChange}
            >
                <FormToggle
                    className={styles.toggle}
                    dataSelectorPrefix={`crossSellLink-${productCode}-toggle`}
                    selectedClassName={styles.isSelected}
                />
            </FormRadioGroup>
        </div>
    )
}

export default CrossSellLinkToggle
